<template >
  <v-container class="limited-width pa-0">
<!--
<v-container fluid class="main-banner pa-0">
<v-container>
  <v-row  align="center"  >
  <v-col align="left" cols="12" md="6" sm="6" order="2" order-sm="1"  >
   <h3>{{ $t('dashbaord-banner-heading') }}</h3>
   <p>Lorem Ipsum drem yunp mora heltik marof cigi cigi tagahal.</p>
   <v-btn 
   prepend-icon="mdi-auto-fix" variant="flat" rounded="xl" size="x-large" color="rgb(var(--v-theme-primary))" class="text-white">
  Generate now
  </v-btn>
  </v-col>
  <v-col align="center" cols="12" md="6" sm="6" order="1" order-sm="2">
  <v-img
  aspect-ratio="1/1"
   src= "../assets/mug1.png" 
   style="max-width: 450px;"
   />
  </v-col>
</v-row>
</v-container>
</v-container>
-->
<home-pge-banner></home-pge-banner>
<productSlider productTag="For couples" :noColor="true"></productSlider>
<productSlider productTag="For her"></productSlider>
<benefitsComp></benefitsComp>
<PromptType></PromptType>
<productSlider productTag="For the little ones"></productSlider>
<productSlider productTag="For him"></productSlider>
<CustomerReview productID='for her' :staffPick="true"></CustomerReview>





</v-container>
</template>

<script>
 import homePgeBanner from '../components/homePgeBanner.vue';
 import productSlider from '@/components/ProductSlider.vue';
 //import GenerationStyles from '../components/GenerationStyles.vue'
 import PromptType from '../components/PromptType.vue'
 import CustomerReview from '@/components/CustomerReview.vue';
 import benefitsComp from '@/components/benefitsComp.vue';

export default {
  name: 'HomeView',
  components: {
    homePgeBanner,
    productSlider,
   // GenerationStyles,
    PromptType,
    CustomerReview,
    benefitsComp
   },
  setup(){
  },
  data(){

  },


}
</script>

<style scoped>
.main-banner{
  background-color: rgb(var(--v-bgAccentColor));
}
.step-col{
  max-width: 279px!important;
}
</style>
