<template>

    <v-dialog v-model="internalModelValue" persistent max-width="800">
        <v-card >
            <v-card-text >
            <v-row>
                <v-col cols="8" align="start">
                    <div class="text-subtitle-1 font-weight-bold ma-0 pa-0">Edit Personalise options</div>
                </v-col>
                <v-col cols="4" align="end">
                    <v-btn color="primary" icon="mdi-close"  @click="closeDialog" size="x-small" variant="outlined"></v-btn>
                 </v-col>
                
                 <v-col cols="12">
                    <v-form @submit.prevent="updateCanvasVariablesInternal(canvasVariables)" ref="form" v-model="formValid">
                    <div v-for="variable in canvasVariables" :key="variable.name" class="ma-0">
                      <!-- Text Input -->
                      <v-col cols="12" class="ma-0 pa-0 pt-3"><div class="text-subtitle-1 font-weight-bold ma-0 pa-0">{{ variable.displayName }}</div></v-col>

                      <v-text-field
                        v-if="variable.variableType === 'text'"
                        :rules="[v => !!v || `⚠️ ${variable.displayName} is required`] "
                        :maxlength="variable.maxLength"
                        v-model="variable.value"
                        variant="outlined"
                        color="primary"
                      ></v-text-field>

                      <!-- Select Input -->
                      
                      <v-select
                        v-else-if="variable.variableType === 'select'"
                       :rules="[v => !!v || `⚠️ ${variable.displayName} is required`] "
                        :items="variable.options"
                        v-model="variable.value"
                        color="primary"
                        variant="outlined"
                        
                      ></v-select>

                
                        <!-- Text to Image Input -->
                        <v-select
                          v-else-if="variable.variableType === 'textToImage'"
                          :rules="[v => !!v || `⚠️ ${variable.displayName} is required`]"
                          :items="variable.options.map(option => option.text)"
                           :model-value="getSelectedText(variable)"
                          @update:model-value="(selected) => updateTextToImage(variable, selected)"
                          color="primary"
                          variant="outlined"
                        ></v-select>

                      <!-- Image Selection -->
                      <v-row v-else-if="variable.variableType === 'image'" class="d-flex flex-wrap">
                        <v-col
                            v-for="image in [...variable.options, 'NO']"
                          :key="image"
                          cols="4"
                          class="d-flex justify-center"
                        >
                        <v-card
                          :class="{'selected-image': variable.value === image}"
                          class="image-card"
                          outlined
                          @click="variable.value = image"
                        >
                          <v-img
                            v-if="image !== 'NO'"
                            :src="image"
                            aspect-ratio="1"
                            width="80"
                            height="80"
                            class="image-thumbnail"
                            
                          ></v-img>
                          <v-img
                            v-else
                            :src="nosrcneede"
                            aspect-ratio="1"
                            width="80"
                            height="80"
                            class="image-thumbnail"
                          > <div  class="no-thumbnail d-flex w-100	h-100">NO</div></v-img>
                        </v-card>
                        </v-col>
                      </v-row>
                    </div>
                </v-form>
                 </v-col>


             <v-col cols="12" align="right">
                <v-btn color="primary" :loading="buttonLoading" size="large" @click="updateCanvasVariablesInternal(canvasVariables)">Preview now</v-btn>
             </v-col>    
            </v-row>
            </v-card-text>
            
             
            
            </v-card>
   
    </v-dialog>

  </template>
  
  <script>
 
  export default {
    props: {
      modelValue: {
        type: Boolean,
        required: true,
      },
      userProduct:{
        type: Object,
        required: true,
      },
      updateCanvasVariables: Function,
    },
    async created(){
        console.log("iim suposed to be up",this.userProduct);
        this.canvasVariables = this.userProduct.canvasVariables;
        this.buttonLoading= false;


    },
    data() {
      return {
        internalModelValue: this.modelValue,
        canvasVariables:[],
        formValid: false,
        buttonLoading:false,
      
      };
    },
    watch: {
      modelValue(newVal) {
        this.internalModelValue = newVal;
      },
      internalModelValue(newVal) {
        this.$emit('update:model-value', newVal);
      },
    },
    methods: {
      closeDialog() {
        this.internalModelValue = false;
      },
      updateTextToImage(variable, selectedText) {
        const selectedOption = variable.options.find(option => option.text === selectedText);
        if (selectedOption) {
          variable.value = selectedOption.url; // Direct assignment in Vue 3

        }
      },
      getSelectedText(variable) {
        const selectedOption = variable.options.find(option => option.url === variable.value);
        return selectedOption ? selectedOption.text : '';
      },
      async updateCanvasVariablesInternal(canvasVariabes) {
      this.buttonLoading= true;
      const canvasVariableValidation = await this.$refs.form.validate();
      if(canvasVariableValidation.valid){
      
          this.updateCanvasVariables(canvasVariabes);
      
      }
    
      },
    },

  };
  </script>
<style scoped>
.image-card {
  cursor: pointer;
  border: 4px solid transparent;
  transition: border 0.3s ease;
}

.image-card.selected-image {
  border-color: #1976d2;
}
.no-thumbnail {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  font-size: 32px;
  font-weight: bold;
  color: #555;
  cursor: pointer;
}

</style>
  