<template>
    <v-container>
        <v-row
         class="d-flex align-left justify-center"
         
         >
        <v-card
   
        align="left"
        class="pa-10"
        >
        <v-row>
            <v-col>
                <div name="termly-embed" data-id="96665ffc-077e-40ca-9389-183ee274d22b"></div>
            </v-col>
        </v-row>
        </v-card>
        </v-row>
    </v-container>
</template>
<script>
export default {
  name: "cookiePolicyView",
  mounted() {
    const scriptId = "termly-jssdk";
    if (!document.getElementById(scriptId)) {
      const script = document.createElement("script");
      script.id = scriptId;
      script.src = "https://app.termly.io/embed-policy.min.js";
      script.async = true;
      document.body.appendChild(script);
    }
  },
};
</script>