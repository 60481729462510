<template>
    <v-dialog v-model="localDialog" max-width="500px" persistent>
      <v-card>
        <v-card-title>
          We only need a face.
        </v-card-title>
        <div class="text-subtitle-2 pl-4 pr-4">Please ensure the face is fully visible within the circle below . You can move and crop the image using 2 fingers. <b>( NO 😎 NO 🤠)</b> </div>
        <v-card-text>
        
            <div class="cropper-container" >
            <img ref="image" :src="imageSrc" />
            <div class="cropper-overlay" ></div>
          </div>
       
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="cancel" variant="outlined" color="info" >Cancel</v-btn>
          <v-btn color="primary" @click="cropImage" prepend-icon="mdi-check" variant="flat">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  import Cropper from 'cropperjs';
  import 'cropperjs/dist/cropper.css';
  //import { trackEvent } from '@/services/amplitudeService';
  
  export default {
    props: {
      modelValue: {
        type: Boolean,
        default: false,
      },
      imageSrc: {
        type: String,
        default: '',
      },
      index: {
        type: Number,
        default: null,
      }
    },
    data() {
      return {
        localDialog: this.modelValue,
        cropper: null,
        cropperLoading: false,
      };
    },
    watch: {
      modelValue(val) {
        this.localDialog = val;
      },
      localDialog(val) {
        this.$emit('update:modelValue', val);
        if (val) {
          this.$nextTick(() => {
            this.initializeCropper();
          });
        } else {
          this.destroyCropper();
        }
      }
    },
    methods: {
      async initializeCropper() {
            if (this.cropper) {
                this.cropper.destroy();
                this.cropper = null;
            }

            const imageElement = this.$refs.image;
            if (!imageElement) return;

            this.cropperLoading = true;

            const processImage = async (image) => {
                return new Promise((resolve) => {
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');
                    const maxDimension = 1024; // Resize image to fit within 1024px (adjust as needed)
                    const { width, height } = image;

                    if (width > maxDimension || height > maxDimension) {
                        const scale = Math.min(maxDimension / width, maxDimension / height);
                        canvas.width = Math.round(width * scale);
                        canvas.height = Math.round(height * scale);
                        ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
                        canvas.toBlob(resolve, 'image/jpeg', 0.8); // Adjust quality as needed
                    } else {
                        resolve(image.src);
                    }
                });
            };

            try {
                const originalImage = new Image();
                originalImage.src = imageElement.src;

                originalImage.onload = async () => {
                    const resizedBlob = await processImage(originalImage);

                    if (resizedBlob instanceof Blob) {
                        const resizedImageURL = URL.createObjectURL(resizedBlob);
                        imageElement.src = resizedImageURL;
                    }

                    const initializeCropper = () => {
                        this.cropper = new Cropper(imageElement, {
                            aspectRatio: 1,
                            viewMode: 3,
                            dragMode: 'move',
                            autoCropArea: 1.0,
                            guides: false,
                            movable: true,
                            scalable: true,
                            cropBoxResizable: false,
                            cropBoxMovable: false,
                            zoomable: true,
                            background: false,
                            checkOrientation: false,
                            ready: () => {
                                const containerData = this.cropper.getContainerData();
                                this.cropper.setCropBoxData({
                                    left: 0,
                                    top: 0,
                                    width: containerData.width,
                                    height: containerData.height,
                                });
                                this.cropperLoading = false;
                                this.$emit('cropReady');
                            },
                        });
                    };

                    if ('requestIdleCallback' in window) {
                        requestIdleCallback(initializeCropper);
                    } else {
                        setTimeout(initializeCropper, 0);
                    }
                };
            } catch (error) {
                console.error('Error initializing Cropper:', error);
                this.cropperLoading = false;
            }
        },
      destroyCropper() {
        if (this.cropper) {
          this.cropper.destroy();
          this.cropper = null;
        }
      },
      async cropImage() {
        if (this.cropper) {
          try {
            const croppedImage = await this.cropper.getCroppedCanvas().toDataURL('image/jpeg');
            this.$emit('crop', croppedImage, this.index);
            this.close();
          } catch (error) {
            console.error('Error cropping image:', error);
          }
        }
      },
      close() {
        this.localDialog = false;
      },
      cancel(){
        this.$emit('crop', null);
        this.$emit('cropReady');
       // trackEvent('Image Cropped closed');
        this.close();
      }
    }
  };
  </script>
  
  <style scoped>
  .cropper-container {
    position: relative;
    width: 100%;
    aspect-ratio: 1 / 1;
  }
  .cropper-container img {
    max-width: 100%;
    max-height: auto;
 
  }
  .cropper-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('../assets/faceguid.svg') center center no-repeat;
    pointer-events: none;
    background-size: cover !important;
    opacity:0.6;
  }
  </style>
  