<template>
    <v-container class="" v-show="this.show">
        <v-row class="mt-4 ml-4 mr-4"><div class="text-subtitle-1 font-weight-bold">Customer reviews</div></v-row>
         <v-row>
            <v-col cols="12">
                <v-slide-group
                v-model="model"
                class="ml-0 mr-0"
                >
                <v-slide-group-item v-for="(review, index) in reviews" :key="index">
                    <v-card
                    class="ma-2 rounded-xl border-thin"
                   
                    width="200"
                    elevation="0"
                    >
                    <v-img
                    cover
                    aspect-ratio="1"
                    :src="this.getImageUrl(review.Image)"
                    align="left"
                    >
                    <v-chip variant="flat" class="position-absolute bottom-0 ma-2"  color="white" size="small">
                        <v-rating
                            half-increments
                            readonly
                            :length="5"
                            :size="15"
                            :model-value=review.Rate
                            active-color="success"
                            color="success"
                            />
                    </v-chip>

                    
                    </v-img>
                    <v-row>
                       <v-col class="">
                        <div class="text-caption pa-2" style="background-color: #fff;" align="left">{{review.Name}} - {{ review.Date }}</div>
                        <div class="text-caption pa-2 weight-bold" style="background-color: #fff;" align="left">{{review.Comment}} </div>
                       </v-col>
                    </v-row>
            
                    </v-card>
                </v-slide-group-item>
            
            </v-slide-group>
            </v-col>
         </v-row>
  
    </v-container>
  </template>
  <script>
  import axios from 'axios';
  export default {
    props: {
        productID: {
        type: String,
        required: true,
      },
      staffPick:{
        type:Boolean,
      }
    
    },
    async created(){
        const response = await axios.post('/apis/getReviews', {
        productID: this.productID,
        staffPick: this.staffPick
        });
    
    this.reviews = response.data.reviews;
    this.show = this.reviews.length;


    },
    data() {
      return {
        reviews:null,
        show:false,      
      };
    },

    methods: {
        getImageUrl(imageURL) {
            // Dynamically generate the full image URL using the environment variable
            const backendUrl = process.env.VUE_APP_BACKEND_URL; // Access the backend URL from .env
            return `${backendUrl}${imageURL}`; // Construct the full URL
    },

    },

  };
  
  </script>
  <style scoped> 

  </style>