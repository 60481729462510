<template>
<v-container class="pl-13 pr-13 pt-4">
    <v-row>
      <v-col align="center" cols="4" md="4" sm="4" >
        <v-row>
          <v-col cols="12" >
            <v-img
            aspect-ratio="1/1"
            src= "../assets/delivery.svg" 
            style="max-width: 40px;"/>
          </v-col>
          <v-col cols="12"  align="center" >
          <!-- <h3>{{ $t('hello') }}</h3>-->
           <div class="text-caption-2  text-uppercase  " align="center">Shipping to 32 countries</div>
          </v-col>
        </v-row>
  
      </v-col>
      <v-col align="center" cols="4" md="4" sm="4"  >
        <v-row>
          <v-col cols="12" >
            <v-img
            aspect-ratio="1/1"
            src= "../assets/cashback.svg" 
            style="max-width: 40px;"/>
          </v-col>
          <v-col cols="12"  align="center" >
            <div class="text-caption-2  text-uppercase " align="center">Money back guarantee</div>
          </v-col>
        </v-row>
  
      </v-col>
      <v-col align="center" cols="4" md="4" sm="4"  >
        <v-row>
          <v-col cols="12" >
            <v-img
            aspect-ratio="1/1"
            src= "../assets/warranty.svg" 
            style="max-width: 40px;"/>
          </v-col>
          <v-col cols="12" align="center" >
           <div class="text-caption-2 text-uppercase " align="center">100% Secure payment</div>
          </v-col>
        </v-row>
  
      </v-col>
    </v-row>
    <v-row>
          
    </v-row>
  </v-container>
</template>