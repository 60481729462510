<template>
    <v-container>
        <v-row
         class="d-flex align-left justify-center"
         
         >
        <v-card
        
        align="left"
        class="pa-10"
        >
        <v-row>
            <v-col>
                <div name="termly-embed" data-id="48b496bc-ecaf-401f-a001-6c14ebc0437c"></div>
            </v-col>
        </v-row>
        </v-card>
        </v-row>
    </v-container>
</template>
<script>
export default {
  name: "returnPolicyView",
  mounted() {
    const scriptId = "termly-jssdk";
    if (!document.getElementById(scriptId)) {
      const script = document.createElement("script");
      script.id = scriptId;
      script.src = "https://app.termly.io/embed-policy.min.js";
      script.async = true;
      document.body.appendChild(script);
    }
  },
};
</script>