  <template>
    <v-container class="limited-width">
        <v-row>
        <v-col class="mb-2">
        <div class="text-h6 text-uppercase " align="left">How it works?</div>
        </v-col>
        </v-row>

        <v-card max-width="600" class="pa-3 rounded-xl" elevation="0" color="#fefafa">
            <v-row no-gutters>
        <v-col cols="4">
            <v-row no-gutters>
            <v-col cols="12" >
                <v-row no-gutters>
                <v-col cols="9">
                <v-img 
                aspect-ratio="1"
                class="rounded-circle border-lg border-primary"
                src="/productImages/person3.jpg"
                ></v-img>

                </v-col>
                <v-col cols="3" class="d-flex align-center">
                <v-icon icon="mdi-chevron-right" size="x-large" color="primary"></v-icon>
                </v-col>
                </v-row>
                <v-col cols="9" class="ma-0 pa-0">
                <div class="text-subtitle-2	" align="center">
                Upload a photo
                </div>
                </v-col>
            </v-col>
            </v-row>
        </v-col>
        <v-col cols="4">
            <v-row no-gutters>
            <v-col cols="12" >
                <v-row no-gutters>
                <v-col cols="9">
                <v-img 
                aspect-ratio="1"
                class="rounded-circle border-lg border-primary"
                src="/productImages/darling-oversize-t-female-closeup.png"
                ></v-img>

                </v-col>
                <v-col cols="3" class="d-flex align-center">
                <v-icon icon="mdi-chevron-right" color="primary" size="x-large" ></v-icon>
                </v-col>
                </v-row>
                <v-col cols="9" class="ma-0 pa-0">
                <div class="text-subtitle-2	" align="center">
                Preview your design
                </div>
                </v-col>
            </v-col>
            </v-row>

        </v-col>
        <v-col cols="4">
            <v-row no-gutters>
            <v-col cols="12" >
                <v-row no-gutters>
                <v-col cols="9">
                <v-img 
                aspect-ratio="1"
                class="rounded-circle border-lg border-primary"
                src="/productImages/Hoodie-Darling-anime-withmodel.png"
                ></v-img>

                </v-col>
                <v-col cols="3" class="d-flex align-center">
                <v-icon icon="mdi-check" color="primary" size="large" ></v-icon>
                </v-col>
                </v-row>
                <v-col cols="9" class="ma-0 pa-0">
                <div class="text-subtitle-2	" align="center">
                Choose size & color
                </div>
                </v-col>
            </v-col>
            </v-row>

        </v-col>
        </v-row>
        </v-card>
    </v-container>
    <v-container class="limited-width">
        <v-row>
        <v-col class="mb-2">
        <div class="text-subtitle " align="left">Filter by tags:</div>
        </v-col>
        </v-row>
    <v-slide-group
      show-arrows
      class="ma-0 pa-0"
      v-model="selectedTag"
    >
      <v-slide-group-item
        v-for="(tag) in tagsRef"
        :key="tag"
        :value="tag"
        v-slot="{ isSelected, toggle }"
      >
        <v-btn
          :color="isSelected ? 'primary' : 'info'"
          class="ma-1"
         :variant="isSelected ? 'tonal' : 'outlined'"
          elevation="0"
          @click="toggle"
          rounded
          size="small"
          :prepend-icon="isSelected ? 'mdi-close-circle-outline' : ''"
        >
          {{tag}}
        </v-btn>
      </v-slide-group-item>
    </v-slide-group>

    </v-container>
    <v-container class="limited-width">
        <v-row>
      <!-- Render each product in a grid -->
      <v-col
        v-for="(product,index) in products"
        :key="product._id"
        class="ma-0 pa-1"
         cols="6" sm="auto"
      >
      <ProductCard :product="product" :wigle="index === 0"></ProductCard>
      </v-col>
    </v-row>

    <!-- Loading spinner when fetching more products -->
    <div ref="loadMore" v-if="isLoading" class="text-center">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    </v-container>



  </template>
  
  <script>
import { ref, watch, onMounted } from "vue";
import axios from "axios";
import ProductCard from "@/components/ProductCard.vue";

// Utility to debounce function calls
function debounce(func, delay) {
  let timer;
  return function (...args) {
    clearTimeout(timer);
    timer = setTimeout(() => func(...args), delay);
  };
}

export default {
    name: 'allProductsPage',
      components:{
        ProductCard,
      },
  setup() {
    // Reactive state
    const products = ref([]); // Loaded products
    const offset = ref(0); // Starting point for pagination
    const limit = ref(12); // Number of products per request
    const total = ref(0); // Total number of products
    const tagsRef = ref([]); // Total number of products
    const isLoading = ref(false); // Prevent duplicate requests
    const selectedTag = ref('');
    

    // Fetch products from the API
    const loadProducts = async () => {
      if (isLoading.value || (offset.value >= total.value && total.value > 0)) return;

      isLoading.value = true;

      try {
        const response = await axios.get("/apis/getAllProducts", {
          params: {
            offset: offset.value,
            limit: limit.value,
            tags: selectedTag.value
          },
        });

        const { products: newProducts, total: totalProducts, tagArray: tagArray} = response.data;


        products.value.push(...newProducts); // Append new products
        total.value = totalProducts; // Update total count
        offset.value += limit.value; // Increment offset
        tagsRef.value = tagArray;
      } catch (error) {
        console.error("Error loading products:", error);
      } finally {
        isLoading.value = false;
      }
    };

    // Debounced version of loadProducts
    const debouncedLoadProducts = debounce(loadProducts, 300);

    watch(selectedTag, () => {
      offset.value = 0; // Reset pagination
      products.value = []; // Clear current products
     
      loadProducts(); // Reload products with the new filter
    });

    // Setup IntersectionObserver for infinite scrolling
    onMounted(() => {
      const observer = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting) {
            debouncedLoadProducts();
          }
        },
        {
          root: null, // Observe relative to the viewport
          rootMargin: "300px", // Trigger 200px before element enters the viewport
          threshold: 0.1, // Element is considered visible if 10% is in the viewport
        }
      );

      const loadMoreElement = document.querySelector(".text-center");
      if (loadMoreElement) observer.observe(loadMoreElement);

      // Load initial products
      loadProducts();
    });


    return {
      products,
      selectedTag,
      offset,
      limit,
      total,
      isLoading,
      tagsRef,
    };
  },
  
};
  

  </script>
  <style scoped>

</style>
