<template>
    <v-container>
        <v-row
         class="d-flex align-left justify-center"
         
         >
        <v-card
       
        align="left"
        class="pa-10"
        >
        <v-row>
            <v-col>
                <h4>Imprint</h4>
                <p><br>Loonify AI OU<br>Address<br>Sepapaja tn 6, 15551 Tallinn, Harju Maakond, Estonia<br>VAT ID Number: We are in the process of VAT registration<br><br>Commercial Register: 17139162<br>Represented by the managing director:<br>Saman Salami<br><br><br></p>
                <h4>Contact</h4>
                <p><br>There is no customer hotline. Please send any customer inquiries to <br>support@loonify.ai<br><br>Responsible for the content displayed :<br><br>Saman Salami<br>Loonify AI OU<br>Street Address<br>14052 Berlin</p>
            </v-col>
        </v-row>
        </v-card>
        </v-row>
    </v-container>
</template>
<script>
</script>