<template>
<v-container class="limited-width pa-0">
      <!-- Display Images -->
      <v-container v-if="!loading">
      <v-row>
        <v-col cols="12" sm="6" md="6">
        <v-carousel
        class="ma-0 pa-0 h-100"
        hide-delimiters
        v-model="carouselIndex">
          <v-carousel-item
          class="ma-0 pa-0"
           v-for="(data, index) in currentImageData"
           :key="index" 
          v-show="true"
          >
            <product-mockup
              :key="data"
              :base64Image="product.base64Material"
              :modelPath="modelPath"
              :aspect-ratio="1"
              :currentImageData="data"
              :MainMaterialLayername = MainMaterialLayername
              :MainMaterialWidth = MainMaterialWidth
              :MainMaterialHeight = MainMaterialHeight
              :is3d="this.baseProduct.Is3d"
            />
          </v-carousel-item>
      </v-carousel>
      </v-col>
      <v-col cols="12" sm="6" md="6">
        <v-col class="pl-2 pl-md-6">
          <v-skeleton-loader
          :loading="loading"
          type="list-item-two-line"
          >
        <v-col cols="12">
        <v-row  class="pl-2 pt-4 pl-md-4 pl-sm-4 pt-md-4 pt-sm-4 "><div class="text-subtitle-1">{{ baseProduct.Name.en }}</div></v-row>
        <v-row  class="pl-2 pl-md-4 pl-sm-4 pt-md-2 pt-sm-2 "><div class="text-h5 font-weight-bold">€ {{ baseProduct.unitPrice }} Euro</div></v-row>
        <v-row class="pt-2 pl-2 pl-md-3 pl-sm-3 pt-md-2 pt-sm-2 mb-4"></v-row>
        <v-divider class="pt-4 mt-4"></v-divider>
        <v-col cols="12">
          <variantComponent :variants="baseProduct.variants" @variantSelected="handleVariantSelected"></variantComponent>
        </v-col>
        <v-divider class="pt-0 mt-0"></v-divider>
        </v-col>
        </v-skeleton-loader>
        <!--FROM HERE-->
        <!--TO HERE-->
        <v-skeleton-loader
        type="list-item-two-line"
        :loading="ShippingMethodsLoading"
        >
                <v-col cols="12" class="">
        <v-row class="pl-2">
            <div class="text-subtitle-2 font-weight-regular" align="left"><v-icon icon="mdi-map-marker" color="success"></v-icon> Shipping to : {{this.countryName.name}}</div>           
        </v-row>
        </v-col>
        <v-col cols="12" class="">
        <v-row class="pl-2">
            <div class="text-subtitle-2 font-weight-regular"><v-icon icon="mdi-truck-check" color="success"></v-icon> Shipping fee : starting at<b> € {{ this.ShippingMethods[selectedMethod].price.toFixed(2) }}</b> </div>           
        </v-row>
        </v-col>

        <v-col cols="12" class="">
        <v-row class="pl-2">
            <div class="text-subtitle-2 font-weight-regular"><v-icon icon="mdi-earth" color="success"></v-icon> Shipping to all EU countries</div>           
        </v-row>
        </v-col>
        <v-divider class="pt-4 mt-4"></v-divider>
        </v-skeleton-loader>
    
    
        <v-row class="d-none d-sm-flex">
        <v-col col="12" class="ma-0" align="left">
            <!--<v-btn  size="large" variant="outlined" color="primary"  prepend-icon="mdi-draw" class="ma-1" @click="editDesign()" >Customize</v-btn>-->
            <v-btn  v-if="Array.isArray(product.canvasVariables) && product.canvasVariables.length > 0"  size="large" variant="outlined" prepend-icon="mdi-pencil-outline" color="primary"  :loading="this.loading" class="ma-1"  @click="showOptionsDialog = true"  >Edit options</v-btn>
            <v-btn @click="buynow" v-if="!outOfStock"  size="large" color="primary"  prepend-icon="mdi-shopping" :loading="this.ShippingMethodsLoading" class="ma-1">Buy now</v-btn>
            <v-btn disabled="true" v-if="outOfStock"   size="large" color="primary"  prepend-icon="mdi-shopping" :loading="this.ShippingMethodsLoading"  class="ma-1">Out of Stock</v-btn>
        </v-col>
      </v-row>
    </v-col>
      </v-col>
      </v-row>
      <v-row>
        <v-row class="mt-6 mt-xs-2 ml-4 mr-4"><div class="text-h6 font-weight-bold">About this item</div></v-row>
      </v-row>
      <v-row>
        <v-row class="mt-4 ml-4 mr-4" ><div align="start" class="text-subtitle-2 font-weight-regular">{{baseProduct.Description.en}}</div></v-row>
      </v-row>
      <v-row>
        <AdditionalInfo :AdditionalInfo="this.baseProduct.AdditionalInfo"></AdditionalInfo>
      </v-row>
      
      <v-row>
        <CustomerReview :productID='baseProduct.subCategory' :staffPick="true"></CustomerReview>
      </v-row>
      <!--App bas sticky-->
      <v-footer
      app
      :elevate-on-scroll="true"
      class="elevation-4 d-flex d-sm-none"
    >
      <v-row>
        <v-col col="12" class="ma-0" align="center">
            <!--<v-btn  size="large" variant="outlined" color="primary"  prepend-icon="mdi-draw" class="ma-1" @click="editDesign()">Customize</v-btn>-->
            <v-btn  v-if="Array.isArray(product.canvasVariables) && product.canvasVariables.length > 0" size="large" variant="outlined" prepend-icon="mdi-pencil-outline" color="primary"  :loading="this.loading" class="ma-1"  @click="showOptionsDialog = true"  >Options</v-btn>
            <v-btn @click="buynow" v-if="!outOfStock"  size="large" color="primary"  prepend-icon="mdi-shopping" :loading="this.ShippingMethodsLoading"  class="ma-1">Buy now</v-btn>
            <v-btn disabled="true" v-if="outOfStock"   size="large" color="primary"  prepend-icon="mdi-shopping" :loading="this.ShippingMethodsLoading"  class="ma-1">Out of Stock</v-btn>
        </v-col>
      </v-row>
    </v-footer>
    <!--end of sticky bar-->
    </v-container>
    <v-container>
      <!-- Loading Dialog -->
      <v-dialog v-model="loading" hide-overlay persistent width="300">
        <v-card color="primary" dark>
          <v-card-text>
            <v-container>
              <v-row justify="center">
                <v-col cols="12" class="text-center">
                  <v-progress-circular indeterminate color="white"></v-progress-circular>
                </v-col>
                <v-col cols="12" class="text-center">
                  Loading designs...
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
    <EditOptionsDialog v-if="!loading" :model-value="showOptionsDialog" :userProduct="product" @update:model-value="showOptionsDialog = $event" :updateCanvasVariables="updateCanvasVariables"/>

  </v-container>
  </template>
  
  <script>
  import axios from 'axios';
  import ProductMockup from '../components/ProductMockup.vue'; // Adjust the import path as necessary
  import mugModelPath from '../assets/mug.glb'; // Import the GLB model 
  import bottelModelPath from '../assets/bottel.glb'; // Import the GLB model 
  import countries from '@/assets/countries.json'; 
  import variantComponent from '@/components/variantComponent.vue';
  import AdditionalInfo from '@/components/AdditionalInfo.vue';
  import CustomerReview from '@/components/CustomerReview.vue';
  import { trackEvent } from '@/services/amplitudeService';
  import EditOptionsDialog from '@/components/EditOptionsDialog.vue';
  export default {
    components: {
    ProductMockup,
    variantComponent,
    AdditionalInfo,
    CustomerReview,
    EditOptionsDialog
    },
    data() {
      return {
        images: [],  // This will store the base64 image data converted to URLs
        loading: true,  // Loading state variable
        modelPath:null,
        product:{},
        selectedProductMockup: null,
        currentImageData:[],
        carouselIndex: 0,
        MainMaterialLayername:'',
        MainMaterialWidth: 10,
        MainMaterialHeight: 10,
        ShippingMethods:[],
        ShippingMethodsLoading: true,
        selectedMethod: null,
        baseProduct:{},
        country:'',
        countryName:'',
        selectedVariant: null,
        currentVariatImage:null,
        outOfStock:false,
        showOptionsDialog:false,

    



      };
    },
     created() {
      const { userProductID } = this.$route.params;
      this.getProduct(userProductID);
     // this.getShippingMethods(userProductID,1);
      
    },
    methods: {
      async getProduct(userProductID) {
        this.loading = true;  // Show loading dialog
        const token = this.$store.getters.getToken; 
        try {
         
          const response = await axios.get(`/apis/getSingleProduct/${userProductID}`, {
          headers: {
          Authorization: token
          }
         });
 
          this.product = response.data;
          const productResponce = await axios.get(`/apis/product/${this.product.productID}`);
          this.baseProduct = productResponce.data;
          this.currentImageData = productResponce.data.imagesData;
          this.MainMaterialLayername = productResponce.data.MainMaterialLayername;
          this.MainMaterialWidth = productResponce.data.MainMaterialWidth;
          this.MainMaterialHeight = productResponce.data.MainMaterialHeight;
          this.product.base64Material = `data:image/png;base64,${this.product.base64Material}`;
          this.product.base64Image =  `data:image/png;base64,${this.product.base64Image}`;

          if(productResponce.data.Category === 'bottles'){
            this.modelPath = bottelModelPath;
          }else if (productResponce.data.Category === 'mugs') {
            this.modelPath = mugModelPath;
          }
          if(response.status==200){
            this.loading=false;
          }
        } catch (error) {
          console.error('Error fetching designs:', error);
          this.$router.replace({ name: '404BadPage' });
        } 
      },
    editDesign(){
      trackEvent('CTA CLICKED', { cta_name: "Edit Design"});
        this.$router.push({ name: 'EditDesignPage', params: { UserProductID: this.product._id} });
    },
    async getShippingMethods(userProductID,quantity){
      this.ShippingMethodsLoading = true;
      const token = this.$store.getters.getToken; 
      try{
        console.log('when loading:',this.selectedVariant)
        if (!this.country){
        const detectedCountry = await axios.get('/apis/getCountry',
        {
        headers: {
          Authorization: token
          }
        }
        );
        const code = detectedCountry.data.country;
        this.country = code;
        this.countryName = countries.find(country => country.code === code) ?? {"Tax_name":"VAT","code":"N/A", "name": "⚠️ Unfortunatly we currently only deliver to th EU countries!", "tax_percent":"100" }; // country name is an object TODO refactor later

        }
        const methods = await axios.post('/apis/getQuote',
        {
          userProductID: userProductID,
          destinationCountry: this.country,
          quantity: quantity,
          externalProductID:this.selectedVariant,
        },
        {
          headers: {
          Authorization: token
          }
        }
      );
      this.ShippingMethods = methods.data;
      if (this.ShippingMethods[0].shipmentMethodUid == 'api_out_of_stock_for_part_order'){
          this.outOfStock = true;
        }else{
          this.outOfStock = false;
        }
      }catch(e){
        console.log(e);
        this.$router.replace({ name: '404BadPage' });
      }finally{
        this.selectCheapestMethod();
        this.ShippingMethodsLoading = false;
      }
      
    },
    selectMethod(index) {
      this.selectedMethod = index;
    },
    selectCheapestMethod() {
      const cheapestMethodIndex = this.ShippingMethods.reduce((lowestIndex, method, index, methods) => {
        return method.price < methods[lowestIndex].price ? index : lowestIndex;
      }, 0);
      this.selectedMethod = cheapestMethodIndex;
    },
    buynow(){
      trackEvent('CTA CLICKED', { cta_name: "BuyNow"});
      this.$router.push({ name: 'checkOut', params: { userProductID: this.product._id, selectedVariant: this.selectedVariant} });
    },
    handleVariantSelected(externalProductId) {
      trackEvent('Variant selected');
    this.selectedVariant = externalProductId;

      // Ensure selectedVariant is set, then call getShippingMethods
      if (this.selectedVariant) {
        const { userProductID } = this.$route.params;
        this.getShippingMethods(userProductID, 1);
        // update current image data from variat selection loop all variats and get the images daat for the externalProductId

      for (let size of this.baseProduct.variants.sizes) {
      if (size.colors) {
        // Loop through each color in the size
        for (let color of size.colors) {
          // Check if the external product ID matches
          if (color.external_product_id === externalProductId) {
            // Return the imagesData array if found
            this.currentImageData = color.imagesData || [];
           
          }
        }
      }
    }

     
      }
    },
    async updateCanvasVariables(canvasVariabes){
      const url = `/apis/updateProductCanvasVariables/${this.product._id}`; 
      const payload = {
        canvasVariables: canvasVariabes,
      };      const token = this.$store.getters.getToken; 

      const headers = {
        Authorization: token, // Replace `authToken` with your token variable
      };
      try{
        await axios.post(url, payload, { headers });  
  

      }catch(e){
        console.log(e);
      }finally{
        const { userProductID } = this.$route.params;
        this.getProduct(userProductID);
        this.showOptionsDialog = false;
      }

    
    },

   },
  };
  </script>
  
  <style scoped>
  .selected-image {
    border: 2px solid rgb(var(--v-theme-primary))!important;  /* Example: Green border */
  }
  </style>
  