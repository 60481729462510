<template >

    <v-container v-if="issmAndUp">
     <v-carousel cycle interval="5000"  :show-arrows="false"   hide-delimiters>
    <v-carousel-item  
    v-for="(item, index) in this.items"
    :key="index">
     <v-row  >
        <v-col cols="12" class="position-relative " style="margin-top: 150px;" >
            <v-card
            class="rounded-xl w-100  gradient-card"
            height="300"
              elevation="0"
              style="background-color: #ff7300; overflow: visible;"
            >
            <v-row>
                <v-col cols="5" align="left">
                    <v-img
                    width="130"
                    class="rounded-circle border-xl border-surface-light ml-10 mt-4"
                    :src="this.getImageUrl(item.personImage)"
                    ></v-img>
                    <div class="text-h5 font-weight-bold  ml-10 mb-2" align="left"  style="max-width:300px; color:#fff; z-index: -1;">{{item.text}}</div>
                    <v-chip size="x-large" color="#fff" variant="outlined" prepend-icon="mdi-auto-fix" class=" ml-10 mb-10" @click="redirectto(item.link)">{{ item.styleText }}</v-chip>
                </v-col>
                <v-col cols="7" class="position-absolute bottom-0 right-0 ma-0 pa-0">
                    <v-img
                    max-height="450"
                    class="ma-0 "
                    fit
                    :src="this.getImageUrl(item.genImage)"
                    align="left"
                    ></v-img>
                </v-col>
            </v-row>
            </v-card>

        </v-col>
    </v-row>
    </v-carousel-item>
    </v-carousel>
    </v-container>
    <v-container v-if="!issmAndUp" style="max-height: 250px!important; " class="mb-13">
     <v-carousel cycle interval="5000"  :show-arrows="false"   hide-delimiters>
        <v-carousel-item  
    v-for="(item, index) in this.items"
    :key="index">
     <v-row  >
        <v-col cols="12"  style="margin-top: 40px;">
            <v-card
            class="rounded-xl w-100  gradient-card"
            height="250"
              elevation="0"
              style="background-color: #ff7300; overflow: visible;"
            >
            <v-row>
                <v-col cols="7" align="left">
                    <v-img
                    width="90"
                    class="rounded-circle border-xl border-surface-light ml-2 mt-4"
                    :src="this.getImageUrl(item.personImage)"
                    :eager="true"
                    ></v-img>
                    <div class="text-caption-2 font-weight-bold  ml-2 mb-2" align="left"  style="max-width:400px; color:#fff;">{{item.text}}</div>
                      <v-chip size="" color="#fff" variant="outlined" prepend-icon="mdi-auto-fix" class=" ml-2 pa-1" @click="redirectto(item.link)">{{ item.styleText }}</v-chip>
                </v-col>
                <v-col cols="8" xs="8" class="position-absolute bottom-0 right-0 ma-0 pa-0" style="z-index: -1;">
                    <v-img
                    max-height="450"
                    class="ma-0 pl-7"
                    
                    :src="this.getImageUrl(item.genImage)"
                    :eager="true"
                    align="left"
                    ></v-img>
                </v-col>
            </v-row>
            </v-card>
        </v-col>
    </v-row>
    </v-carousel-item>

    
    </v-carousel>
    </v-container>
    
    </template>
    
    <script>
    import { useDisplay } from 'vuetify';
    
    export default {
      name: 'GenerationStyles',
      setup(){
      },
      data(){
        return{
        issmAndUp: false,
        styles:[],
        colors:["#3b1a7f","#0061ef","#ffe14f","#ff7300","#00a4ff","#9e66c6","#3b1a7f","#0061ef","#ffe14f","#ff7300","#00a4ff","#9e66c6","#3b1a7f","#0061ef","#ffe14f","#ff7300","#00a4ff","#9e66c6","#3b1a7f","#0061ef","#ffe14f","#ff7300","#00a4ff","#9e66c6",],
        items:[
            {
                personImage:"/productImages/person3.jpg",
                text:"Imagine me wearing a kimoni!",
                styleText:"Gifts for her",
                genImage:"/productImages/kimono.png",
                link:"For her"
            },
            {
                personImage:"/productImages/person1.jpg",
                text:"Imagine me riding a rooster!",
                styleText:"Gifts for kids",
                genImage:"/productImages/gen-01.png",
                 link:"For the little ones"
            },
            {
                personImage:"/productImages/person2.jpg",
                text:"Imagine me as John Snow!",
                styleText:"Gifts for him",
                genImage:"/productImages/john-snow.png",
                 link:"For him"
            },
        ],
        }
      },
      created(){
        const { smAndUp } = useDisplay();
        this.issmAndUp =smAndUp;
      
    
      },
      mounted(){
       
      },
      methods:{
        getImageUrl(imageURL) {
                // Dynamically generate the full image URL using the environment variable
                const backendUrl = process.env.VUE_APP_BACKEND_URL; // Access the backend URL from .env
                return `${backendUrl}${imageURL}`; // Construct the full URL
        },
        redirectto(link){

          this.$router.push({ name: "ProductsPage" , params: {"ProductTag": link}});
        }
      }
    
    }
    </script>
    <style scoped>
    .gradient-card {
      background: radial-gradient(circle, rgba(255,255,255,0.5) 0%, rgba(225,115,0,1) 100%);
     
    }
    </style>