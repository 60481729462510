<template >
  <v-container class="limited-width ">
   <v-container >
      <!-- Loading Dialog -->
      <v-dialog  v-model="loading" hide-overlay persistent width="400">
        <v-card color="primary" dark>
          <v-card-text>
            <v-container>
              <v-row justify="center">
                <v-col cols="12" class="text-center">
                  <div class="text-subtitle-2">Please wait...our AI is working on it.</div>
                </v-col>
                <v-col cols="12" class="text-center">
                  <v-img
                  :key="currentGif"
                  :src="currentGif"
                  ></v-img>
   
                <v-progress-linear
                  color="waring"
                  height="6"
                  indeterminate
                  rounded
                ></v-progress-linear>
                </v-col>
                <v-col cols="12" class="text-center">
                  <div class="text-subtitle-2">This can take up to 3 minutes 🤯. Generating AI images is hard work. </div>
                  <div class="text-caption pt-2">💌 We will send you an Email notification when your design is ready! </div>
                  
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
      <!-- Display Images -->
      <v-container v-if="!this.loading"  class="pa-0" >
      <v-row no-gutters class="">
        <v-col cols="12" sm="6" md="6" >
        <v-carousel
         v-if="selectedProduct"
        hide-delimiters
        class="ma-0 pa-0 h-100"
        v-model="carouselIndex">
          <v-carousel-item
           v-for="(data, index) in currentImageData"
           :key="index"
          v-show="true"
            class="ma-0 pa-0"
          >
            <product-mockup
              :key="data"
              :base64Image="selectedProduct.base64Material"
              :modelPath="modelPath"
              :aspect-ratio="1"
              :currentImageData="data"
              :MainMaterialLayername = "MainMaterialLayername"
              :MainMaterialWidth ="MainMaterialWidth"
              :MainMaterialHeight="MainMaterialHeight"
              :is3d="this.baseProduct.Is3d"
              
            />
          </v-carousel-item>
      </v-carousel>
      </v-col>
      
      <v-col cols="12" sm="6" md="6">
        <v-col class="pl-2 pl-md-6">
          <v-skeleton-loader
          :loading="loading"
          type="list-item-two-line"
          > 
        <v-col cols="12" class="pa-0" align="left">
        <v-row class="pl-2 pt-4 pl-md-4 pl-sm-4 pt-md-4 pt-sm-4 "><div class="text-subtitle-1">{{ this.baseProduct.Name.en }}</div></v-row>
        <v-row class="pl-2 pl-md-4 pl-sm-4 pt-md-2 pt-sm-2 "><div class="text-h5 font-weight-bold">€ {{ this.baseProduct.unitPrice }} Euro</div></v-row>
        <v-row class="pt-3 pl-2 pl-md-4 pl-sm-4 pt-md-4 pt-sm-4 " v-if="products.length > 1" ><div class="text-subtitle-2 font-weight-bold">Choose your Character</div></v-row>
        <v-row class="pt-2 pl-2 pl-md-3 pl-sm-3 pt-md-2 pt-sm-2 mb-4" v-if="products.length > 1">
        <v-col v-for="(product, index) in products" :key="index" cols="3" sm="3" md="3" class="pa-1" >
            <v-img
             :class="{'selected-image': selectedProduct._id === product._id}"
             @click="setSelectedProduct(product._id)"
             :src="product.charBase64Image"
             class="rounded-lg border-md"
            ></v-img>
        </v-col>
        </v-row>
      </v-col>
      <v-divider class="pt-4 mt-4"></v-divider>
      <v-col  cols="12">
          <variantComponent :variants="baseProduct.variants" @variantSelected="handleVariantSelected"></variantComponent>
      </v-col>
      <v-divider class="pt-1 mt-1 pb-3"></v-divider>
      </v-skeleton-loader>

        <v-row class="border-t-sm ml-1 mr-1 mb-2"></v-row>
        <!--from here-->
        <v-skeleton-loader
        type="list-item-two-line"
        :loading="ShippingMethodsLoading"
        >
        <v-col cols="12" class="pl-0">
        <v-row class="pl-2">
            <div class="text-subtitle-2 font-weight-regular"  align="left"><v-icon icon="mdi-map-marker" color="success"></v-icon> Shipping to : {{this.countryName.name}}</div>           
        </v-row>
        </v-col>
        <v-col cols="12" class="pl-0">
        <v-row class="pl-2">
            <div class="text-subtitle-2 font-weight-regular"><v-icon icon="mdi-truck-check" color="success"></v-icon> Shipping fee : starting at<b> € {{ this.ShippingMethods[selectedMethod].price.toFixed(2) }}</b> </div>           
        </v-row>
        </v-col>

        <v-col cols="12" class="pl-0">
        <v-row class="pl-2">
            <div class="text-subtitle-2 font-weight-regular"><v-icon icon="mdi-earth" color="success"></v-icon> Shipping to all EU countries. </div>           
        </v-row>
        </v-col>
        <v-divider class="pt-4 mt-4"></v-divider>
        </v-skeleton-loader>
         <!--to here-->
        <v-row class="d-none d-sm-flex">
        <v-col col="12" class="ma-0" align="left">
            <!--<v-btn  size="large" variant="outlined" color="primary"  prepend-icon="mdi-draw" class="ma-1" @click="editDesign()" >Customize</v-btn>-->
            <v-btn  v-if="Array.isArray(selectedProduct.canvasVariables) && selectedProduct.canvasVariables.length > 0"  size="large" variant="outlined" prepend-icon="mdi-pencil-outline" color="primary"  :loading="this.productLoading" class="ma-1"  @click="showOptionsDialog = true"  >Edit options</v-btn>
            <v-btn @click="buynow" v-if="!outOfStock"  size="large" color="primary" :loading="this.ShippingMethodsLoading"  prepend-icon="mdi-shopping" class="ma-1">Buy now</v-btn>
            <v-btn disabled="true" v-if="outOfStock"   size="large" color="primary"  prepend-icon="mdi-shopping" :loading="this.ShippingMethodsLoading"  class="ma-1">Out of Stock</v-btn>

            
        </v-col>
      </v-row>
      </v-col>
    </v-col>
      </v-row>

      <v-row no-gutters>
        <v-row class="mt-6 mt-xs-2 ml-4 mr-4"><div class="text-h6 font-weight-bold">About this item</div></v-row>
      </v-row>
      <v-row no-gutters>
        <v-row class="mt-4 ml-4 mr-4" ><div align="start" class="text-subtitle-2 font-weight-regular">{{baseProduct.Description.en}}</div></v-row>
      </v-row>
      <v-row no-gutters>
        <AdditionalInfo :AdditionalInfo="this.baseProduct.AdditionalInfo"></AdditionalInfo>
      </v-row>

      <v-row  no-gutters>
        <CustomerReview :productID='baseProduct.subCategory' :staffPick="true"></CustomerReview>
      </v-row>

      <!--App bas sticky-->
      <v-footer
      app
      :elevate-on-scroll="true"
      class="elevation-4 d-flex d-sm-none"
    >
      <v-row no-gutters>
        <v-col col="12" class="ma-0" align="center">
            <!--<v-btn  size="large" variant="outlined" color="primary"  prepend-icon="mdi-draw" class="ma-1" @click="editDesign()">Customize</v-btn>-->
            <v-btn  v-if="Array.isArray(selectedProduct.canvasVariables) && selectedProduct.canvasVariables.length > 0" size="large" variant="outlined" prepend-icon="mdi-pencil-outline" color="primary"  :loading="this.productLoading" class="ma-1"  @click="showOptionsDialog = true"  >Options</v-btn>
            <v-btn  size="large" v-if="!outOfStock"  color="primary" @click="buynow"  :loading="this.ShippingMethodsLoading"  prepend-icon="mdi-shopping" class="ma-1">Buy now</v-btn>
            <v-btn disabled="true" v-if="outOfStock"   size="large" color="primary"  prepend-icon="mdi-shopping" :loading="this.ShippingMethodsLoading"  class="ma-1">Out of Stock</v-btn>
        </v-col>
      </v-row>
    </v-footer>
    <!--end of sticky bar-->
    </v-container>
    <EditOptionsDialog v-if="!loading" :model-value="showOptionsDialog" :userProduct="selectedProduct" @update:model-value="showOptionsDialog = $event" :updateCanvasVariables="updateCanvasVariables"/>

  </v-container>
  </template>
  
  <script>
  import axios from 'axios';
  import ProductMockup from '../components/ProductMockup.vue'; // Adjust the import path as necessary
  import mugModelPath from '../assets/mug.glb'; // Import the GLB model 
  import bottelModelPath from '../assets/bottel.glb'; // Import the GLB model 
  //import { construct } from 'core-js/fn/reflect';
  import countries from '@/assets/countries.json'; 
  import variantComponent from '@/components/variantComponent.vue';
  import AdditionalInfo from '@/components/AdditionalInfo.vue';
  import CustomerReview from '@/components/CustomerReview.vue';
  import { trackEvent } from '@/services/amplitudeService';
  import EditOptionsDialog from '@/components/EditOptionsDialog.vue';


  export default {
    components: {
    ProductMockup,
    variantComponent,
    AdditionalInfo,
    CustomerReview,
    EditOptionsDialog
    },
    data() {
      return {
        images: [],  // This will store the base64 image data converted to URLs
        loading: true,  // Loading state variable
        mugModelPath,
        selectedProduct:null,
        products:[],
        selectedProductMockup: null,
        productLoading: false,
        currentImageData:[],
        carouselIndex: 0,
        MainMaterialLayername:'',
        MainMaterialWidth: 10,
        MainMaterialHeight: 10,
        modelPath:null,
        gifs: [
        "/productImages/wait.webp",  
       "/productImages/wait-2.webp",  
       "/productImages/robot.webp", 
       "/productImages/working.webp", 
       "/productImages/close.webp", 
       "/productImages/almost.webp", 
       "/productImages/working-hard.webp", 
      ],
     
      currentGifIndex: 0,
      interval: null,
      ShippingMethods:[],
      ShippingMethodsLoading: true,
      selectedMethod: null,
      baseProduct:{},
      country:'',
      countryName:'',
      selectedVariant: null,
      outOfStock: false,
      showOptionsDialog:false,
      wakeLock: null,

      };
    },
    created() {
      const { productID, promptID } = this.$route.params;
      this.getDesigns(productID, promptID);
      
    },
    mounted(){
      this.interval = setInterval(this.changeVideo, 5000);
    },
    beforeUnmount() {
    // Clear the interval when the component is destroyed
    clearInterval(this.interval);
   },
    methods: {
      async getDesigns(productID, promptID) {
        this.wakeLock = await this.requestWakeLock();
        console.log('Wake lock acquired:', this.wakeLock );
        const token = this.$store.getters.getToken; 
        this.loading = true;  // Show loading dialog
        const productResponce = await axios.get(`/apis/product/${productID}`);
        this.baseProduct = productResponce.data;
        //need to get canvasVariabes from prompt not base product. TODO

        const canvasVariables = this.baseProduct.canvasVariables 

        try {
          const response = await axios.post('/apis/getDesigns', { productID, promptID, canvasVariables},
          {
          headers: {
          Authorization: token
          },
          timeout: 600000, // 10 minutes
          }
          );
        
          this.products = response.data.products;
          if (this.products.length > 0) {
             this.selectedProduct = this.products[0];
          }
          for ( const product of this.products){
             product.base64Material = `data:image/png;base64,${product.base64Material}`;
             product.base64Image =  `data:image/png;base64,${product.base64Image}`;
             product.charBase64Image = `data:image/png;base64,${product.charBase64Image}`;
          }
          
          
          this.currentImageData = productResponce.data.imagesData;
          this.MainMaterialLayername= productResponce.data.MainMaterialLayername;
          this.MainMaterialWidth = productResponce.data.MainMaterialWidth;
          this.MainMaterialHeight = productResponce.data.MainMaterialHeight;
          if(productResponce.data.Category === 'bottles'){
            this.modelPath = bottelModelPath;
          }else if (productResponce.data.Category === 'mugs') {
            this.modelPath = mugModelPath;
          }
          
          if (!this.country){
          const detectedCountry = await axios.get('/apis/getCountry',
          {
          headers: {
            Authorization: token
            }
          }
          );
          const code = detectedCountry.data.country;
          this.country = code;
          this.countryName = countries.find(country => country.code === code) ?? {"Tax_name":"VAT","code":"N/A", "name": "⚠️ Unfortunatly we currently only deliver to the EU countries!", "tax_percent":"100" }; // country name is an object TODO refactor later

          }
         // this.getShippingMethods(this.selectedProduct._id,1,this.country);
          if(response.status == 200){
            this.loading=false;
          }


        } catch (error) {
          location.reload();
          console.warn('Request timed out. Retrying...');
          if (error.code === 'ECONNABORTED' || error.message.includes('timeout') || error.code === 'ERR_NETWORK' ) {
            
              if (document.visibilityState === 'visible') {
                console.warn('Request timed out. Retrying...');
                //TODO redirect to static page
              }
              
            }

        } finally{
          this.releaseWakeLock(this.wakeLock);
          this.wakeLock = null;
        }
      },
      async setSelectedProduct(productId) {
        this.selectedProduct = this.products.find(product => product._id === productId);
    },
    editDesign(){
      trackEvent('CTA CLICKED', { cta_name: "Edit Design"});
        this.$router.push({ name: 'EditDesignPage', params: { UserProductID: this.selectedProduct._id} });
    },
    getImageUrl(imageURL) {
            // Dynamically generate the full image URL using the environment variable
            const backendUrl = process.env.VUE_APP_BACKEND_URL; // Access the backend URL from .env
            return `${backendUrl}${imageURL}`; // Construct the full URL
    },
    changeVideo() {
      // Change to the next video
      this.currentGifIndex = (this.currentGifIndex + 1) % this.gifs.length;
    },
    //here
    async getShippingMethods(userProductID,quantity,destinationCountry){
     
      this.ShippingMethodsLoading = true;
      const token = this.$store.getters.getToken; 
      

      try{

        if (!this.country){
        const detectedCountry = await axios.get('/apis/getCountry',
        {
        headers: {
          Authorization: token
          }
        }
        );
        const code = detectedCountry.data.country;
        this.country = code;
        this.countryName = countries.find(country => country.code === code) ?? {"Tax_name":"VAT","code":"N/A", "name": "Unfortunatly we currently only deliver to th EU countries!", "tax_percent":"100" }; // country name is an object TODO refactor later
      

        }
        const methods = await axios.post('/apis/getQuote',
        {
          userProductID: userProductID,
          destinationCountry: destinationCountry,
          quantity: quantity,
          externalProductID:this.selectedVariant,
        },
        {
          headers: {
          Authorization: token
          }
        }
      );
      this.ShippingMethods = methods.data;
        if (this.ShippingMethods[0].shipmentMethodUid == 'api_out_of_stock_for_part_order'){
          this.outOfStock = true;
        }else{
          this.outOfStock = false;
        }
      }catch(e){
        console.log(e);
      }finally{
        this.selectCheapestMethod();
        this.ShippingMethodsLoading = false;
      }
      
    },
    selectMethod(index) {
      this.selectedMethod = index;
    },
    selectCheapestMethod() {
      const cheapestMethodIndex = this.ShippingMethods.reduce((lowestIndex, method, index, methods) => {
        return method.price < methods[lowestIndex].price ? index : lowestIndex;
      }, 0);
      this.selectedMethod = cheapestMethodIndex;
    },
    buynow(){
      trackEvent('CTA CLICKED', { cta_name: "BuyNow"});
      this.$router.push({ name: 'checkOut', params: { userProductID: this.selectedProduct._id, selectedVariant: this.selectedVariant} });
    },
    handleVariantSelected(externalProductId) {
      trackEvent('Variant selected');
    this.selectedVariant = externalProductId;
      // Ensure selectedVariant is set, then call getShippingMethods
      if (this.selectedVariant) {
        this.getShippingMethods(this.selectedProduct._id, 1, this.country);

        for (let size of this.baseProduct.variants.sizes) {
          if (size.colors) {
            // Loop through each color in the size
            for (let color of size.colors) {
              // Check if the external product ID matches
              if (color.external_product_id === externalProductId) {
                // Return the imagesData array if found
                this.currentImageData = color.imagesData || [];
               
              }
            }
          }
        }
      



      }
    },
    async updateCanvasVariables(canvasVariabes){
      const url = `/apis/updateProductCanvasVariables/${this.selectedProduct._id}`; 
      const token = this.$store.getters.getToken; 
      const payload = {
        canvasVariables: canvasVariabes,
      };
      const headers = {
        Authorization: token, // Replace `authToken` with your token variable
      };
      try{
        
        await axios.post(url, payload, { headers });  

      }catch(e){
        console.log(e);
      }finally{
        const { productID, promptID } = this.$route.params;
        this.getDesigns(productID, promptID);
        this.showOptionsDialog = false;
      }

    
    },
    async requestWakeLock() {
    if ('wakeLock' in navigator) {
      try {
        const wakeLock = await navigator.wakeLock.request('screen');
        return wakeLock;
      } catch (err) {
        console.error('Wake Lock request failed:', err);
      }
    } else {
      console.warn('Wake Lock API not supported in this browser.');
    }
    return null;
  },
  releaseWakeLock(wakeLock) {
    if (wakeLock) {
      wakeLock.release().then(() => {
        console.log('Wake Lock released');
      });
    }
  },


    },
    computed: {
    currentGif() {
      return this.getImageUrl(this.gifs[this.currentGifIndex]);
    }
  },
    
  };
  </script>
  
  <style scoped>
  .selected-image {
    border: 2px solid rgb(var(--v-theme-primary))!important;  /* Example: Green border */
  }
  </style>
  