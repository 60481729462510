<template>
    <v-container class="limit-width">
        <v-row>
        <v-row class="d-flex justify-center align-center">
  <v-col cols="12" sm="6" class="text-left">
    <div class="text-h1">ABOUT US</div>
  </v-col>
</v-row>
        <v-col cols="12" md="6" align="left">
       
        <p class="mb-4">
          Welcome to Loonify.ai, where creativity meets technology! We are a small, passionate, and self-funded startup
          proudly registered in Estonia and managed from the vibrant city of Berlin, Germany. At Loonify, we believe that gifts are more than just objects; they are expressions of emotion, thoughtfulness, and individuality.
        </p>
        <p class="mb-4">
            Our mission is to make personalized gifting effortless, delightful, and accessible to everyone. 
            Whether it’s creating bespoke designs for mugs, t-shirts, or other unique items, we strive to bring a "WOW" to every product. By combining advanced AI algorithms with an intuitive and user-friendly platform, we empower our customers to transform their ideas into tangible, one-of-a-kind keepsakes that make memories last a lifetime.
        </p>
      </v-col>

      <v-col cols="12"  style="margin-top: 40px;">
            <v-card
            class="rounded-xl w-100  gradient-card w-100"
            height="250"
         
              elevation="0"
              style="background-color: #ff7300; overflow: visible;"
            >
            <v-row class="mt-13">
                <v-col cols="12" sm="6"  class="position-absolute bottom-0 left-0 ma-0 pa-0" style="z-index: -1;">
                    <v-img
                    max-height="450"
                    class="ma-0 pl-0"
                    fit
                    src="http://www.loonify.ai/assets/founding-team.png" 
                    align="left"
                    eager=""
                    ></v-img>
                </v-col>
                

            </v-row>
            </v-card>
        </v-col>
    </v-row>
  

  

    </v-container>
  </template>
  <script>
  export default {
    data: () => ({
      show: false,
    }),
  }
</script>