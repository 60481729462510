<template>
 <v-container class="limit-width pl-2 pr-2" theme="myCustomTheme">
    <v-dialog v-model="showEmailModal" max-width="600px" persistent transition="dialog-bottom-transition"  >
        <v-card>
            <v-row class="pa-0 ma-0">
                <v-col cols="12 pb-1">
                    <div class="text-h6 pt-4 pl-6 pr-6 pb-0">Email for privacy reasons</div>
                </v-col>
                <v-col cols="12">
                    <div class="text-subtitle-2 pr-6 pt-0 pl-6 pb-4"><b>We will never contact you for marketing or promotional reasosn without your consent.</b>We use this email only to protect your personal information.</div>
                </v-col>
                <v-col cols="12">
                    <v-form class="pt-0 pl-6 pr-6 pb-6" @submit.prevent="handleSubmit">
                        <v-text-field type="email" autocomplete="email" @input="removeSpaces" :rules="emailRules" color="primary" v-model="email" density="compact" variant="outlined" label="Your E-mail" prepend-icon=""  prepend-inner-icon="mdi-email" placeholder="example@gmail.com"></v-text-field>
                        <v-checkbox v-if="false" color="primary" v-model="Agreed" class="pa-0 ma-0">
                        <template v-slot:label>
                            <div class="p-0 m-0">
                            I agree with
                            <!--TODO change the url for privacy policy and terms of use-->
                                <a  class="pa-0 ma-0 "
                                    href="https://www.loonify.ai/privacypolicy" 
                                    target="_blank"
                                    v-bind="props"
                                    @click.stop
                                >
                                    privacy policy
                                </a>
                                 <a  class="pa-0 ma-0 "

                                    v-bind="props"
                                    @click.stop
                                >
                                   &
                                </a>
                                 <a  class="pa-0 ma-0 "
                                    href="https://www.loonify.ai/termsandconditions" 
                                    target="_blank"
                                    v-bind="props"
                                    @click.stop
                                >
                                    T&C
                                </a>
                            </div>
                        </template>
                        </v-checkbox>
                        <div  align="left">
                            <v-btn :loading="loading" size="large" type="submit" color="primary" prepend-icon="mdi-check" class="ma-1" >continue</v-btn>
                            <v-btn size="small" color="primary" class="ma-1" variant="text" @click="closeEmailMocal">Cancel</v-btn>
                            <div style="font-size: x-small !important;" class="text-subtitle-2">By clicking continue you agree to our <a  class="pa-0 ma-0 "
                                    href="https://www.loonify.ai/privacypolicy" 
                                    target="_blank"
                                    v-bind="props"
                                    @click.stop
                                >
                                    privacy policy
                                </a></div>
                       </div>
                    </v-form>
                </v-col>
            </v-row>
        </v-card>
    </v-dialog>
    <v-row class="bg ma-1  rounded" >
        <v-col align="center"  cols="12" sm="7" class="ma-0 pa-0">
            <v-skeleton-loader
             :loading="productLoading"
             type="image"
            >
            <v-carousel
            class="ma-0 pa-0 h-100"
            hide-delimiter-background
            :show-arrows="false"
            v-if="product"
            v-model="carouselIndex">
              <v-carousel-item
              class="ma-0 pa-0"
              v-for="(image, index) in product.Thumbnails"
              :key="index" 
              v-show="true"
              >
              <v-img
                    :maxwidth="200"
                    :src="getImageUrl(image)"
                    rounded
                    color="#f7f1f1"
                    aspect-ratio="1"
                    @click="showImage = true"
                    eager
                    class="outer-img"
                    >


      
              <!-- Image Popup -->

                </v-img>

              </v-carousel-item>
              </v-carousel>
              <teleport to="body">
                <vue-easy-lightbox
                  v-if="showImage"
                  :visible="showImage"
                  :imgs=product.Thumbnails
                  @hide="showImage = false"
                  :index="carouselIndex"
                  :swipeToNavigate="true"
                />
              </teleport>




        </v-skeleton-loader>

        </v-col>
        <v-col cols="12" sm="5">
            <v-row class="pa-xs-0 pa-md-0 ">
                <v-col align="left">
                    <v-skeleton-loader
                     :loading="productLoading "
                     type="paragraph"
                        >
                        <v-row no-gutters>
                            <v-col cols="12" >
                            <span class="text-subtitle-1 font-weight-bold mt-2">{{ this.product.Name.en }}</span>
                            </v-col>
                            <v-col cols="12">
                            <v-chip class="text-subtitle-1 font-weight-bold mt-2" color="primary">€ {{ this.product.unitPrice }}</v-chip>
                            </v-col>
                            <v-col cols="12">
                              <div class="text-subtitle-2 font-weight-bold mt-2">Availble sizes: <v-chip v-for="(variatn,index) in product.variants.sizes" class="text-caption" :key="index" variant="text" size="x-small" >{{ variatn.size }}</v-chip></div>
                            </v-col>
                            <v-col cols="12">
                              <div ref="targetSection" id="target-section"></div>
                              <div class="text-subtitle-2 font-weight-bold mt-2">Deliver to: <span :class="`fi fi-${this.countryName.code.toLowerCase()}`"></span> <v-chip variant="text" class="text-caption"  size="x-small" >{{ this.countryName.name }}</v-chip> </div>

                            </v-col>
                            <!--
                            <v-col cols="12">
                             
                            <v-chip 
                            v-for="(tag,index) in product.designTags"
                            :key="index"
                            color="info" variant="outlined" size="x-small" prepend-icon="mdi-pound" class="mr-1" >
                            {{ tag }}
                            </v-chip>
                            </v-col>
                            -->

                        </v-row>    
                    </v-skeleton-loader>
                </v-col>
            </v-row>
        </v-col>
    </v-row>

    
</v-container>
<v-container class="limit-width pl-2 pr-2 pt-0 mt-5" v-show="!productLoading">

  <v-card  class="pa-3 border-thin" elevation="0" style=" overflow: visible;" color="#fefbfb" >
    <v-chip @click="scrollToSection" class="d-flex border-thin " prepend-icon="mdi-arrow-down-left" elevation="1" append-icon="mdi-arrow-down-right" style="position:absolute; top:-20px; left:50%; overflow: visible; transform: translateX(-50%); " color="warning" variant="flat" size="large" ref=shakeButton >Personalize Options</v-chip>
         <v-row class="">
            <v-col align="left" class="mt-4">
              <div ref="formD">
                <v-form @submit.prevent="handleSubmit" ref="form" v-model="formValid">
                  <v-row>
                  <v-col  :cols="this.inputImages[1] ? 6 : 12 ">
                    <div class="text-subtitle-1 font-weight-bold">Upload {{inputImages[0]?.title}}</div>
                    <div class="text-caption mb-3"><b>Personalize with {{inputImages[0]?.title}}.</b> This can be an image of you or any of your loved ones. just remember the face should be fully visible.</div>
                    <v-col cols="12" align="center" class="border-dashed rounded border-md mb-2">
                    <img v-if="croppedImage && !cropDialog" @click="browseFiles(0)" class="rounded-circle border-dashed border-lg pa-2 cursor-pointer" style="max-width: 100px;" :src="this.croppedImage">
                            <v-btn v-if="!croppedImage"
                            color="primary"
                            class="file-input-btn"
                            @click="browseFiles(0)"
                            :loading="cropperLoading "
                            icon="mdi-file-image-plus-outline"
                        ></v-btn>
                        <div @click="browseFiles(0)" color="link" v-if="!croppedImage" class="text-subtitle-2 cursor-pointer">Click to uplaod</div>
                        <div @click="browseFiles(0)" color="link" v-if="croppedImage" class="text-subtitle-2 cursor-pointer">Click to change</div>
                    </v-col>
                    <div v-if="imageInValid" class="text-caption" color="error">⚠️ Please select an input photo!</div>
                    <v-file-input ref="fileInput"  v-model="image" density="compact" label="Image" variant="outlined" prepend-icon=""  prepend-inner-icon="mdi-camera" accept="image/*"  @change="onFileChange($event, 0)" style="display: none;" ></v-file-input>
                    <ImageCropper
                    :index="0"
                    :modelValue="cropDialog"
                    :imageSrc="imageSrc"
                    @update:modelValue="cropDialog = $event"
                    @crop="(croppedImage) => onCrop(croppedImage, 0)"
                     @cropReady="() => cropReady(0)"
                    />
                  </v-col>
                  <v-col :cols="this.inputImages[1] ? 6 : 6 " v-if="inputImages[1]">
                    <div class="text-subtitle-1 font-weight-bold ">Upload {{inputImages[1]?.title}}</div>
                    <div class="text-caption mb-3"><b>Personalize with {{inputImages[1]?.title}}.</b> This can be an image of you or any of your loved ones. just remember the face should be fully visible.</div>
                    <v-col cols="12" align="center" class="border-dashed rounded border-md mb-2">
                    <img v-if="croppedImage1 && !cropDialog1" @click="browseFiles(1)" class="rounded-circle border-dashed border-lg pa-2 cursor-pointer" style="max-width: 100px;" :src="this.croppedImage1">
                            <v-btn v-if="!croppedImage1"
                            color="primary"
                            class="file-input-btn"
                            @click="browseFiles(1)"
                            :loading="cropperLoading1"
                        icon="mdi-file-image-plus-outline"
                        ></v-btn>
                        <div @click="browseFiles(1)" color="link" v-if="!croppedImage1" class="text-subtitle-2 cursor-pointer">Click to uplaod</div>
                        <div @click="browseFiles(1)" color="link" v-if="croppedImage1" class="text-subtitle-2 cursor-pointer">Click to change</div>
                    </v-col>
                    <div v-if="imageInValid1" class="text-caption" color="error">⚠️ Please select an input photo!</div>
                    <v-file-input ref="fileInput1"  v-model="image1" density="compact" label="Image" variant="outlined" prepend-icon=""  prepend-inner-icon="mdi-camera" accept="image/*" @change="onFileChange($event, 1)" style="display: none;" ></v-file-input>
                    <ImageCropper
                    :index="1"
                    :modelValue="cropDialog1"
                    :imageSrc="imageSrc1"
                    @update:modelValue="cropDialog1 = $event"
                    @crop="(croppedImage) => onCrop(croppedImage, 1)"
                    @cropReady="() => cropReady(1)"
                    />
                    
                  </v-col>

                  <v-col cols="12">
                    <div v-for="variable in canvasVariables" :key="variable.name" class="ma-0">
                      <!-- Text Input -->
                      <v-col cols="12" class="ma-0 pa-0 pt-3"><div class="text-subtitle-1 font-weight-bold ma-0 pa-0">{{ variable.displayName }}</div></v-col>

                      <v-text-field
                        v-if="variable.variableType === 'text'"
                        :rules="[v => !!v || `⚠️ ${variable.displayName} is required`] "
                        :maxlength="variable.maxLength"
                        v-model="variable.value"
                        variant="outlined"
                        color="primary"
                      ></v-text-field>

                      <!-- Select Input -->
                      
                      <v-select
                        v-else-if="variable.variableType === 'select'"
                       :rules="[v => !!v || `⚠️ ${variable.displayName} is required`] "
                        :items="variable.options"
                        v-model="variable.value"
                        color="primary"
                        variant="outlined"
                        
                      ></v-select>
                        <!-- Text to Image Input -->
                        <v-select
                          v-else-if="variable.variableType === 'textToImage'"
                          :rules="[v => !!v || `⚠️ ${variable.displayName} is required`]"
                          :items="variable.options.map(option => option.text)"
                           :model-value="getSelectedText(variable)"
                          @update:model-value="(selected) => updateTextToImage(variable, selected)"
                          color="primary"
                          variant="outlined"
                        ></v-select>

                      <!-- Image Selection -->
                      <v-row v-else-if="variable.variableType === 'image'" class="d-flex flex-wrap">
                        <v-col
                            v-for="image in [...variable.options, 'NO']"
                          :key="image"
                          cols="4"
                          class="d-flex justify-center"
                        >
                        <v-card
                          :class="{'selected-image': variable.value === image}"
                          class="image-card"
                          outlined
                          @click="variable.value = image"
                        >
                          <v-img
                            v-if="image !== 'NO'"
                            :src="image"
                            aspect-ratio="1"
                            width="80"
                            height="80"
                            class="image-thumbnail"
                            
                          ></v-img>
                          <v-img
                            v-else
                            :src="123"
                            aspect-ratio="1"
                            width="80"
                            height="80"
                            class="image-thumbnail"
                          > <div  class="no-thumbnail d-flex w-100	h-100">NO</div></v-img>
                        </v-card>
                        </v-col>
                      </v-row>
                    </div>

                  </v-col>
                </v-row>

                <!--adding befire this for custim file upload-->    
                <v-text-field    v-model="email" density="compact" variant="outlined" label="Your E-mail" prepend-icon=""  prepend-inner-icon="mdi-email" style="display:none"></v-text-field>
                <!--TODO handle in case of canseling file selection-->
                <div class="text-h6" v-if="this.needUserPrompt">2.Describe the Character's Activity</div>
                <div class="text-caption mb-3" v-if="this.needUserPrompt">Describe what your charachter should be doing. You can also click on our suggested prompts.  </div>
                <v-textarea v-if="this.needUserPrompt" :rules="[ rules.required, rules.min, rules.max]" color="primary" v-model="prompt" density="compact" clearable label="Promt" variant="outlined" prepend-icon=""  prepend-inner-icon="mdi-creation" placeholder="Ex. dressed like a superhero riding a bike.  "></v-textarea>
                <div v-if="!this.hasValidToken" class="text-subtitle-1 font-weight-bold">Your Email</div>
                <v-text-field v-if="!this.hasValidToken"  type="email" autocomplete="email" @input="removeSpaces" :rules="emailRules" color="primary" v-model="email" density="compact" variant="outlined" label="Your E-mail" prepend-icon=""  prepend-inner-icon="mdi-email" placeholder="example@gmail.com"></v-text-field>
                <div v-if="!this.hasValidToken"  class="text-subtitle-2 pr-6 pt-0 pl-6 pb-4"><b>We will never contact you for marketing or promotional reasosn without your consent.</b>We use this email only to protect your personal information.</div>

                <div  ref="mainCta" id="main-cta">
                  <v-btn type="submit" size="large" color="primary"  prepend-icon="mdi-creation" class="mt-8 " :loading="loading" >Generate design</v-btn>
                </div>
                <div v-if="!this.hasValidToken" style="font-size: x-small !important;" class="text-subtitle-2">By clicking continue you agree to our <a  class="pa-0 ma-0 "
                                    href="https://www.loonify.ai/privacypolicy" 
                                    target="_blank"
                                    v-bind="props"
                                    @click.stop
                                >
                                    privacy policy
                                </a></div>

                
                <!--added this for canvasVariables-->


                <!--added this for cropper-->


                </v-form>
              </div>
                
                <v-footer
                app
                :elevate-on-scroll="true"
                class="elevation-4 d-flex d-sm-none border-md"
                v-if="!isCtaVisible"
                
              >

                <v-row>
                  <v-col col="12" class="ma-0" align="center">
                    <v-btn type="submit" size="large" color="primary"  prepend-icon="mdi-creation" class="mt-2 " :loading="loading" variant="outlined" @click="scrollToSection" >Personalize</v-btn>
                  </v-col>
                </v-row>
              </v-footer>
        </v-col>
        </v-row>
      </v-card>
   
</v-container>
<v-container class="limited-width">
  <div class="text-h6 pl-2 pt-13" align="left">You might like</div>
  <v-row class="pt-4">

    <v-skeleton-loader
      :loading="productLoading "
       type="paragraph"
       >

    <productSlider :productTag="this.product.designTags[0]" :noColor="false" :showHeader="false"></productSlider>
    </v-skeleton-loader>
  </v-row>
  <v-row class="pt-4">
                <CustomerReview :productID='product.subCategory' v-if="!productLoading" :staffPick="true"></CustomerReview>
  </v-row>
</v-container>


</template>

<script>
import axios from 'axios';
import ImageCropper from '../components/CroperComponent.vue';
import { mapActions } from 'vuex';
import VueEasyLightbox from "vue-easy-lightbox";
import CustomerReview from '@/components/CustomerReview.vue';
import { trackEvent } from '@/services/amplitudeService';
import countries from '@/assets/countries.json'; 
import ProductSlider from '@/components/ProductSlider.vue';


  export default{
    name: 'GenerateDesign',
    components: { ImageCropper,VueEasyLightbox,CustomerReview,ProductSlider },
    data() {
        return {
         email: null,
         image: null,
         prompt: '',
         product: null,
         ptoductId:null,
         productId:null,
         productLoading: true,
         //added this for cropper
         imageSrc: null,
         cropDialog: false,
         croppedImage: null,
         //end of  cropper
         showEmailModal: false,
         imageInValid: false,
         Agreed:true,
         loading: false,
         needUserPrompt:false,
         //added for 2nd input image [refactor later]
         croppedImage1: null,
         cropDialog1: false,
         image1: null,
         imageSrc1: null,
         imageInValid1: false,
         showImage:false,
         carouselIndex:0,
         isCtaVisible: false, 
         country: null,
         countryName: null,
         rules: {
           required: value => !!value || '⚠️ Please enter a valid prompt!',
           min: value => !!value && value.length > 10 || '⚠️ Your prompt is too short',
           max: value => !!value && value.length < 100 || '⚠️ Your prompt is too long',
          },
          emailRules: [
             value => !!value || '⚠️ Email is required.',
             value => /.+@.+\..+/.test(value) || '⚠️ Email must be valid.',
          ],
          inputImages: [],
          canvasVariables:[],
          formValid: false,
          cropperLoading: false,
          cropperLoading1: false,
          hasValidToken:false,
         
          };
          
    },
    async created(){
    const productName = this.$route.params.productName;
    let result;
    try{
    // result = await axios.get(`/apis/product/${productId}`); //TODO check for invalid product ID and show 404 (no such product)
     result= await axios.get(`/apis/productname/${productName}`);
     const detectedCountry = await axios.get('/apis/getCountry',
        {
        headers: {
         
          }
        }
        );
       
        const code = detectedCountry.data.country;
        this.country = code;
        this.countryName = countries.find(country => country.code === code) ?? {"Tax_name":"VAT","code":"N/A", "name": "⚠️ Unfortunatly we currently only deliver to EU countries!", "tax_percent":"100" }; // country name is an object TODO refactor later
        const currentTime = Math.floor(Date.now() / 1000); 
        const token = this.$store.getters.getToken; 
        const exp = this.$store.getters.getExp; 
        this.hasValidToken = !!(token && exp > currentTime);
    

      

    }catch(e){
      console.log(e);
    }finally{
      const product = result.data;
      this.product = product;
      console.log(this.product)
      this.canvasVariables = product.canvasVariables;
      this.addJSONLD();
      this.inputImages = product.inputImages;
      this.needPrompt();
      this.productLoading =false;
      this.productId = product._id
      
    }

  },
  mounted(){
    this.simulateSlide(); // Target only the first carousel
    this.observeMainCta();
    this.$nextTick(() => {

      this.canvasVariables.forEach(variable => {
        if (variable.variableType === 'image' && !variable.options.includes(variable.value)) {
          variable.value = variable.options[0]; // Set to first option if value is invalid
        }
      });
    });

    
    
  },
  computed: {
    areAllRulesMet() {
      // Iterate through the rules and check if all are met
      return (this.emailRules.every(rule => rule(this.email) === true) && this.Agreed);
    },
  },
  watch: {
    'this.productId '() {
      this.needPrompt(); // Load new data when route changes
    }
  },
  methods:{
    ...mapActions(['login']),
    ...mapActions(['tempRegister']),
    needPrompt(){
      if(this.product.needUserPrompt == 'False'){
      this.prompt = "Prompt not Requiered!";
      this.needUserPrompt = false;
      }else{
        this.prompt = null;
        this.needUserPrompt = true;
      }
    },
    async handleSubmit() {
    trackEvent('CTA CLICKED', { cta_name: "Generate Design"});
     const currentTime = Math.floor(Date.now() / 1000); 
     const token = this.$store.getters.getToken; 
     const exp = this.$store.getters.getExp; 
     this.scrollToSection();
     const canvasVariableValidation = await this.$refs.form.validate();
   

     if(token && exp > currentTime ){
      if(this.areImagesValid() && this.prompt && canvasVariableValidation.valid){
        this.loading =true;
          const formData = new FormData();
          formData.append('prompt', this.prompt);
          formData.append('productId',this.productId );
          formData.append('canvasVariables', JSON.stringify(this.canvasVariables));
          const blob = this.dataURItoBlob(this.croppedImage);
          formData.append('image', blob, 'croppedImage.jpg'); // Append the Blob with a filename
          if(this.croppedImage1){
            const blob1 = this.dataURItoBlob(this.croppedImage1);
            formData.append('image1', blob1, 'croppedImage1.jpg'); 
          }
        try{
          const response = await axios.post('/apis/addUserPrompt', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `${token}`
          }
        });
        const prompt_id = response.data.record._id;
        trackEvent('Prpmpt added');
        this.$router.push({ name: 'ViewDesigns', params: { promptID: prompt_id, productID: this.productId  } });
        this.loading = false;
        }catch (error){
          console.error('Error:', error);
          this.loading = false;
        }
      }
     }else if(token && exp < currentTime ){
      
      this.$router.push({ 
        name: 'loginPage', 
        query: { redirect: this.$route.fullPath } 
      });
      this.loading = false;
     }else if(this.areImagesValid() && this.email  && this.prompt && this.Agreed && canvasVariableValidation.valid){
      
      this.loading =true;
       const response = await axios.post('/apis/checkUserName',{username: this.email});
       if(response.data.userNameExist){
        //show login popup or redirect to login 
        this.$router.push({ 
        name: 'loginPage', 
        query: { redirect: this.$route.fullPath } 
      });
       }else{
        //register user and login 

        await this.tempRegister({ username: this.email});
        const token = this.$store.getters.getToken; 
        const formData = new FormData();
        formData.append('prompt', this.prompt);
        formData.append('productId',this.productId );
        formData.append('canvasVariables', JSON.stringify(this.canvasVariables) );
        // Convert base64 to Blob
        const blob = this.dataURItoBlob(this.croppedImage);
        formData.append('image', blob, 'croppedImage.jpg'); // Append the Blob with a filename
        if(this.croppedImage1){
            const blob1 = this.dataURItoBlob(this.croppedImage1);
            formData.append('image1', blob1, 'croppedImage1.jpg'); 
          }
        try {
            const response = await axios.post('/apis/addUserPrompt', formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `${token}`
              }
            });
            const prompt_id = response.data.record._id;
            this.$router.push({ name: 'ViewDesigns', params: { promptID: prompt_id, productID: this.productId  } });
            this.loading = false;
            // redirect to next step
            
          } catch (error) {
            console.error('Error:', error);
            this.loading = false;
          }
        //then create record and redirect
       }

    } else if (!this.email && this.prompt.length>10 && this.areImagesValid() && this.formValid){
      console.log(this.areAllRulesMet);
      this.showEmailModal = true;
      trackEvent('Register Modal opened');
    }
    },
    // added this fro cropper 
    async onFileChange(event, index) {
    if(index === 0){
      this.cropperLoading = true; // Start showing the loading spinner
    }
    if(index === 1){
      this.cropperLoading1 = true; // Start showing the loading spinner
    }
  
    const file = event.target.files[0];

    if (file && file.type.startsWith('image/')) {
        try {
            trackEvent('Image Selected');

            // Use requestAnimationFrame to allow UI to render
            await new Promise((resolve) => requestAnimationFrame(resolve));

            const reader = new FileReader();

            reader.onload = (e) => {
                if (index === 0) {
                    this.imageSrc = e.target.result;
                    this.cropDialog = true; // Open the cropper dialog
                } else if (index === 1) {
                    this.imageSrc1 = e.target.result;
                    this.cropDialog1 = true;
                }
            };

            reader.readAsDataURL(file);
        } catch (e) {
            console.error('Error processing file:', e);
        } finally {
            // Small delay to ensure dialog is fully opened before hiding spinner
           
              // this.cropperLoading = false;
           
        }
    } else {
       
      if(index === 0){
      this.cropperLoading = false; // Start showing the loading spinner
    }
    if(index === 1){
      this.cropperLoading1 = false; // Start showing the loading spinner
    }
    }
},
    onCrop(croppedImage, index) {
 
    if (croppedImage) {
     
      if (index === 0) {
        this.croppedImage = croppedImage;
        this.imageInValid = false;
      } else if (index === 1) {
        this.croppedImage1 = croppedImage;
        this.imageInValid1 = false;
      }
    }else{
      if (index === 0) {
      this.croppedImage = null;
      }
      if (index === 1) {
      this.croppedImage1 = null;
      }
    }
  },
    dataURItoBlob(dataURI) {
      const byteString = atob(dataURI.split(',')[1]);
      const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], { type: mimeString });
    },
    browseFiles(index) {
      trackEvent('Browse files');
      if (index == 0){
        this.croppedImage = null;
        this.image = null;
        this.$refs.fileInput.click();
      }
      if(index == 1){
        this.croppedImage1 = null;
        this.image1 = null;
        this.$refs.fileInput1.click();
      }

    },
    addJSONLD() {
      const jsonLd = {
        "@context": "https://schema.org",
        "@type": "Product",
        "name": this.product.seoName,
        "image": `https://loonify.ai${this.product.Thumbnails[0]}`,
        "description": this.product.Description.en,
        "sku": this.product.subCategory,
      };

      const script = document.createElement("script");
      script.type = "application/ld+json";
      script.textContent = JSON.stringify(jsonLd);
      document.head.appendChild(script);
    },
    removeSpaces() {
      // Remove spaces from the email input value
      this.email = this.email.replace(/\s/g, '');
    },
    closeEmailMocal(){
      trackEvent('Register Modal Closed');
       // this.email=null;
       // this.Agreed=false;
        this.showEmailModal = false;
    },
    getImageUrl(imageURL) {
            // Dynamically generate the full image URL using the environment variable
            const backendUrl = process.env.VUE_APP_BACKEND_URL; // Access the backend URL from .env
            return `${backendUrl}${imageURL}`; // Construct the full URL
    },
    areImagesValid(){
      const numNeeded = this.inputImages.length;
   
      if(numNeeded == 1){
        if(!this.croppedImage){
          this.imageInValid = true;
          return false;
        }else{
          this.imageInValid = false;
          return true;
        }
      }else if (numNeeded == 2){
        if(!this.croppedImage && !this.croppedImage1){
          this.imageInValid = true;
          this.imageInValid1 = true;
          return false;
        }else if(!this.croppedImage && this.croppedImage1){
          this.imageInValid = true;
          this.imageInValid1 = false;
          return false
        }else if(this.croppedImage && !this.croppedImage1){
          this.imageInValid = false;
          this.imageInValid1 = true;
          return false
        }
        else if(this.croppedImage && this.croppedImage1){
          this.imageInValid = false;
          this.imageInValid1 = false;
          return true
        }

      }
    },
    simulateSlide() {
            
            setTimeout(() => {
              this.carouselIndex = 1; // Moves to the second slide
            }, 500);

            setTimeout(() => {
              this.carouselIndex = 0; // Returns to the first slide
            }, 1000);
          
        },
    observeMainCta() {
      const mainCta = this.$refs.formD;

      if (!mainCta) return;

      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            this.isCtaVisible = entry.isIntersecting;
            
          });
        },
        {
          root: null, // Use the viewport as the root
          threshold: 0.4, // Trigger when at least 10% of the CTA is visible
        }
      );

      observer.observe(mainCta);
    },
    scrollToSection() {
      const target = this.$refs.targetSection; // Access the target section using the ref
      if (target) {
        target.scrollIntoView({ behavior: 'smooth' }); // Smooth scroll to the target section
      }
    },
    cropReady(index){
             
      if(index === 0){
      this.cropperLoading = false; // Start showing the loading spinner
    }
    if(index === 1){
      this.cropperLoading1 = false; // Start showing the loading spinner
    }
    },
    updateTextToImage(variable, selectedText) {
    const selectedOption = variable.options.find(option => option.text === selectedText);
    if (selectedOption) {
      variable.value = selectedOption.url; // Direct assignment in Vue 3
    }
  },
  getSelectedText(variable) {
    const selectedOption = variable.options.find(option => option.url === variable.value);
    return selectedOption ? selectedOption.text : '';
  },
    
  },

}
</script>

<style scoped> 
.limit-width{
    max-width: 700px;
}
.file-input-btn {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.outer-img {
  position: relative; /* Make the outer v-img a relative container */
}

.inner-img {
  position: absolute; /* Position the inner v-img absolutely within the outer v-img */
  bottom: 0; /* Align to the bottom */
  left: 20px; /* Align to the left */
  transform: translate(-10%, -10%); /* Optional: Slight offset for styling */
  z-index: 2; /* Ensure it appears on top */
}
.image-thumbnail {

  cursor: pointer;
}

.image-card {
  cursor: pointer;
  border: 4px solid transparent;
  transition: border 0.3s ease;
}

.image-card.selected-image {
  border-color: #1976d2;
}
.no-thumbnail {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  font-size: 32px;
  font-weight: bold;
  color: #555;
  cursor: pointer;
}
@keyframes shake {
  0% { transform: translateX(0); }
  25% { transform: translateX(-5px); }
  50% { transform: translateX(5px); }
  75% { transform: translateX(-5px); }
  100% { transform: translateX(0); }
}

.shake {
  animation: shake 0.5s ease;
}


  
</style>