// store.js

import Vuex from 'vuex';
import axios from 'axios';


export default new Vuex.Store({
  state: {
    token: localStorage.getItem('token') || '',
    exp: localStorage.getItem('exp') || null,  // Store exp in state
    currency: 'EUR', // Default currency
  },
  mutations: {
    setToken(state, { token, exp }) {
      state.token = token;
      state.exp = exp;
    },
    clearToken(state) {
        state.token = null;
        state.exp = null;
      },
      setCurrency(state, newCurrency) {
        state.currency = newCurrency;
      },
  },
  actions: {
    async login({ commit }, credentials) {
      try {
        const response = await axios.post('/apis/login', credentials);
    
        const token = response.data.token;
        const exp = response.data.exp;
    
        // Save token and expiry in localStorage
        localStorage.setItem('token', token);
        localStorage.setItem('exp', exp);
    
        // Commit token to the Vuex store
        commit('setToken', { token, exp });
    
        // Return success
        return true;
      } catch (error) {
        console.error('Login failed:', error);
    
        // Optionally handle specific error cases here
    
        // Return failure
        return false;
      }
    },
    async tempRegister({ commit }, credentials) {
        const response = await axios.post('/apis/tempRegister', credentials);
        const token = response.data.token;
        const exp = response.data.exp;
        localStorage.setItem('token', token);
        localStorage.setItem('exp', exp);
        commit('setToken', { token, exp });
      },
      logout({ commit }) {
      commit('clearToken');
      localStorage.removeItem('token');  // Clear token from localStorage
      localStorage.removeItem('exp');    // Clear expiration from localStorage
      console.log('logedout');
      },
      updateCurrency({ commit }, newCurrency) {
        commit('setCurrency', newCurrency);
      },
  },
  getters: {
    getToken: state => state.token, // Get the token from the state
    getExp: (state) => state.exp, 
    isLoggedIn: state => !!state.token,
    currentCurrency: (state) => state.currency,
    
  },

    
});